import toasts from "./toasts";
let validation = {
    //Размер указывать в кб
    file: (file, fileTypes, size = 8000) => {
        if (file) {
            const EXT = file.name.match(/\.\w+$/)[0].replace('.', '');
            if (!fileTypes.includes(EXT)) {
                toasts.error(`Допустимые форматы: ${fileTypes.map(type => type)}`);
                return false;
            } else if (file.size > size * 1000) {
                toasts.error(`Максимальный размер изображения: ${size}кб`);
                return false;
            }
            return true;
        }
    },
};
export default validation;
