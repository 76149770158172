let toasts = {
    error(text, timer = 3000) {
        Swal.fire({
            toast: true,
            position: 'top-end',
            title: text,
            icon: "error",
            showConfirmButton: false,
            timer: timer,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        })
    }
};
export default toasts;
